function isMobile() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /mobi|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
}

export const BrowserSize = () => {
  var docEl = document.documentElement,
    clientWidth,
    clientHeight,
    clientWidth = docEl.clientWidth;
  clientHeight = docEl.clientHeight;
  if (!clientWidth) return;
  if (!docEl.addEventListener) return;
  if (isMobile()) {
    if (clientWidth > 1920) {
      clientWidth = 1920;
    }  else if (clientWidth < 1400 && clientWidth > 768) {
      clientWidth = 1440;
    } else {
      clientWidth = clientWidth;
    }
  } else {
    if (clientWidth < 1200) {
      clientWidth = 1200;
    } else if(clientWidth>1920){
      clientWidth = 1920
    }else{
      clientWidth = clientWidth
    }
  }
  if (clientWidth > clientHeight) {
    docEl.style.fontSize = clientWidth / 10 + "px";
  } else {
    docEl.style.fontSize = clientWidth / 10 + "px";
  }
};
