// 宣传页模块
// 最新路由
export default [
  {
    path: "/user-member",
    name: "user-member",
    component: () => import("@/page/home/commercialization/member/member.vue"),
    meta: {
      title: "会员中心",
      hiddenFooter: false,
      mobileSupported: true,
    },
  },
  {
    path: "/firm-service",
    name: "firm-service",
    component: () => import("@/page/home/commercialization/service/index.vue"),
    meta: {
      title: "企业服务",
      hiddenFooter: false,
    },
  },
  {
    path: "/firm-service2",
    name: "firm-service",
    component: () => import("@/page/home/commercialization/service2/index.vue"),
    meta: {
      title: "企业服务",
      hiddenFooter: false,
    },
  },
  {
    path: "/memberDialog",
    name: "memberDialog",
    component: () =>
      import("@/page/home/commercialization/member/memberDialog.vue"),
    meta: {
      title: "会员购买",
      hiddenNav: true,
      hiddenFooter: true,
    },
  },
  {
    path: "/modelingService",
    name: "modelingService",
    component: () =>
      import("@/page/home/commercialization/modelingService/index.vue"),
    meta: {
      title: "建模服务",
      hiddenFooter: false,
    },
  },
  {
    path: "/campus",
    name: "campus",
    component: () => import("@/page/home/commercialization/campus/index.vue"),
    meta: {
      title: "校园计划",
      hiddenFooter: false,
    },
  },
  {
    path: "/roboLink",
    name: "roboLink",
    component: () => import("@/page/home/commercialization/roboLink/index.vue"),
    meta: {
      title: "RoboLink",
      hiddenFooter: false,
      hiddenRightBar: true,
    },
  },
  {
    path: "/roboLink/appointment-form",
    name: "appointmentForm",
    component: () =>
      import("@/page/home/commercialization/roboLink/appointment-form.vue"),
    meta: {
      title: "申请试用·RoboLink",
      hiddenFooter: false,
      hiddenRightBar: true,
      mobileSupported: true,
    },
  },
  {
    path: "/industry-ai",
    name: "industryAi",
    component: () =>
      import("@/page/home/commercialization/industryAi/index.vue"),
    meta: {
      title: "工业智能,引领未来",
      hiddenFooter: true,
      hiddenRightBar: true,
      hideHeader: true,
    },
  },
  {
    path: "/industry-ai/form",
    name: "industryAiForm",
    component: () =>
      import("@/page/home/commercialization/industryAi/form.vue"),
    meta: {
      title: "工业智能,引领未来",
      hiddenFooter: true,
      hiddenRightBar: true,
      hideHeader: true,
      mobileSupported: true,
    },
  },
  {
    path: "/industry-ai/knowledgeBase",
    name: "knowledgeBase",
    component: () =>
      import("@/page/home/commercialization/industryAi/knowledgeBase.vue"),
    meta: {
      title: "企业智能知识库",
      hiddenFooter: true,
      hiddenRightBar: true,
      hideHeader: true,
      mobileSupported: true,
    },
  },
  {
    path: "/industry-ai/robolink",
    name: "aiRobolink",
    component: () =>
      import("@/page/home/commercialization/industryAi/robolink.vue"),
    meta: {
      title: "超真云RoboLink",
      hiddenFooter: true,
      hiddenRightBar: true,
      hideHeader: true,
      mobileSupported: true,
    },
  },
  {
    path: "/industry-ai/mes",
    name: "aimes",
    component: () => import("@/page/home/commercialization/industryAi/mes.vue"),
    meta: {
      title: "超真云MES",
      hiddenFooter: true,
      hiddenRightBar: true,
      hideHeader: true,
      mobileSupported: true,
    },
  },
  {
    path: "/omniknow",
    name: "omniknow",
    component: () =>
      import("@/page/home/commercialization/industryAi/omniknow.vue"),
    meta: {
      title: "超真云RoboLink",
      hiddenFooter: true,
      hiddenRightBar: true,
      hideHeader: true,
      mobileSupported: true,
    },
  },
  {
    path: "/cad",
    name: "cad",
    component: () => import("@/page/home/commercialization/cad/index.vue"),
    meta: {
      title: "CAD看图助手",
      hiddenFooter: false,
      hiddenRightBar: true,
    },
  },
  {
    path: "/openview",
    name: "openview",
    component: () => import("@/page/home/commercialization/openview/index.vue"),
    meta: {
      title: "Openview",
    },
  },
  {
    path: "/commercialization/ai1200",
    name: "ai1200",
    component: () => import("@/page/home/commercialization/ai1200/index_copy.vue"),
    meta: {
      title: "ai1200",
    },
  },
  {
    path: "/gnosisLargeModel",
    name: "gnosisLargeModel",
    component: () => import("@/page/home/commercialization/ai1200/index.vue"),
    meta: {
      title: "GNOSIS真知大模型",
      hiddenFooter: true,
      hideMobileHeader: true,
      // requiresAuth: true,
    },
  },
  {
    path: "/vrVisitSystem",
    name: "vrVisitSystem",
    component: () => import("@/page/home/commercialization/vrVisit/index.vue"),
    meta: {
      title: "VR 探视系统",
      hiddenFooter: false,
      hideMobileHeader: true,
      // requiresAuth: true,
    },
  },

  {
    path: "/commercialization/omniKnow",
    name: "commercializationOmniKnow",
    component: () => import("@/page/home/commercialization/omniKnow/index.vue"),
    meta: {
      title: "Omni Know | 真知企业知识库",
    },
  },
];
