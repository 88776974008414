<!-- 新版登录与注册 -->

<template>
  <!--  -->
  <el-dialog
    custom-class="login-regis1"
    :modal="true"
    :visible.sync="showDialog"
    :show-close="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="dialog-content">
      <img src="../../assets/newImg/login/group.png" alt="" srcset="" />
      <div class="content1">
        <div class="left">
          <div class="tel">
            <div
              class="title"
              :class="dialogType != 7 ? 'not-check' : ''"
              @click="dialogType = 7"
            >
              手机登录
            </div>
            <div
              class="title"
              :class="dialogType != 6 ? 'not-check' : ''"
              @click="dialogType = 6"
            >
              密码登录
            </div>
            <div
              class="title"
              :class="dialogType != 8 ? 'not-check' : ''"
              @click="dialogType = 8"
            >
              找回密码
            </div>
          </div>
          <el-form
            :inline="false"
            :model="formInline"
            class="demo-form-inline"
            autocomplete="on"
          >
            <span v-if="dialogType == 7 || dialogType == 8">
              <el-form-item label="">
                <div class="phone">
                  <!-- <el-select v-if="dialogType != 7 || dialogType !== 8" v-model="formInline.dialCode" placeholder="">
                    <el-option label="+86" :value="86"></el-option>
                  </el-select> -->
                  <el-input
                    v-model.trim="formInline.account"
                    placeholder="请输入手机号"
                    @input="
                      formInline.account = formInline.account.replace(
                        /\s+/g,
                        ''
                      )
                    "
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <el-input
                  v-model.trim="formInline.password"
                  type="text"
                  placeholder="请输入验证码"
                  @input="
                    formInline.password = formInline.password.replace(
                      /\s+/g,
                      ''
                    )
                  "
                >
                </el-input>
                <span class="send-code-btn" @click="sendCode">{{
                  countdownCount == 60 ? "获取验证码" : countdownCount + "s"
                }}</span>
              </el-form-item>
              <el-form-item label="" v-if="dialogType == 8">
                <el-input
                  v-model.trim="formInline.pwd"
                  :type="pwd ? 'text' : 'password'"
                  placeholder="请输入密码"
                  @input="formInline.pwd = formInline.pwd.replace(/\s+/g, '')"
                >
                  <i
                    slot="suffix"
                    @click="pwd = !pwd"
                    style="padding-top: 10px; display: block"
                  >
                    <img
                      v-if="!pwd"
                      class="input_icon top-sm"
                      src="@/assets/newImg/0704/eye.svg"
                    />
                    <img
                      v-else
                      class="input_icon top-sm"
                      src="@/assets/newImg/0704/eye-off.svg"
                    />
                  </i>
                </el-input>
              </el-form-item>
            </span>
            <span v-else>
              <el-form-item label="">
                <el-input
                  v-model.trim="formInline.account"
                  placeholder="请输入手机号"
                  @input="
                    formInline.account = formInline.account.replace(/\s+/g, '')
                  "
                ></el-input>
              </el-form-item>
              <el-input
                v-model.trim="formInline.password"
                :type="showPassword ? 'text' : 'password'"
                placeholder="请输入密码"
                @input="
                  formInline.password = formInline.password.replace(/\s+/g, '')
                "
              >
                <i slot="suffix" @click="showPassword = !showPassword">
                  <img
                    v-if="!showPassword"
                    class="input_icon top-sm"
                    src="@/assets/newImg/0704/eye.svg"
                  />
                  <img
                    v-else
                    class="input_icon top-sm"
                    src="@/assets/newImg/0704/eye-off.svg"
                  />
                </i>
              </el-input>
            </span>
            <!-- <div class="reset-pwd" @click="isResetPsw = !isResetPsw"
              :style="{ marginTop: dialogType == 7 ? '-10px' : '10px' }">{{
                !isResetPsw ? '找回密码' : '返回登录' }}</div> -->
            <div class="login-btn" @click="submit">
              {{ dialogType == 8 ? "重置密码" : "登 录" }}
            </div>
            <div class="login-desc" v-if="dialogType !== 8">
              未注册手机号将自动创建账号
            </div>
            <div class="book">
              <el-checkbox v-model="ischecked" style="z-index: 999"
                >&nbsp;</el-checkbox
              >默认已阅读同意
              <router-link
                target="_blank"
                :to="{ path: '/serAgreement', query: { title: '服务协议' } }"
                >《平台服务协议》</router-link
              >和
              <router-link
                target="_blank"
                :to="{ path: '/Complaints', query: { title: '隐私政策' } }"
                >《用户隐私协议》</router-link
              >
            </div>
          </el-form>
        </div>
        <div class="pc-wx">
          <div class="title">微信扫码登录</div>
          <div class="wx" v-if="!qrcodeImgUrl">
            <Loading />
          </div>
          <img v-else :src="qrcodeImgUrl" class="wx" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import {
  createBoxsArray,
  validContent,
  validAccount,
} from "@/static/js/login-controller";
import { setUserInfo } from "@/static/js/userInfo";
import { appClickHandle } from "@/static/js/goPage.js";
import { throttle } from "lodash";
import {
  sendPhoneCode,
  loginByPhoneCode,
  checkUsername,
  sendEmailCode,
  verifyCode,
  register,
  bindWithPhone,
  loginRegister,
  resetPsw,
  qrcodeUrl,
  loginOrBind,
} from "@/api/LoginRegister";
import { getChannelInfo } from "@/plugin/webConvert";
import Loading from "@/components/Loading.vue";
export default {
  name: "LoginDialog",
  data() {
    return {
      //默认输入框类型 1-扫码登录；2-验证注册用户名；3-绑定邮箱和密码
      //4-注册设置密码；5-登录成功；6-密码登录;7-手机验证码登录;8-找回密码 10-扫码登录绑定手机号
      dialogType: 7,
      isResetPsw: false, // 是否是找回密码
      showPassword: false,
      pwd: false,
      formInline: {
        dialCode: 86,
      },
      ischecked: true,
      qrcodeImgUrl: "",
      websocket: null,
      showDialog: false, //是否显示弹框
      countdownCount: 60,
      interval: null,
      newQrCode: null,
    };
  },
  components: { Loading },
  computed: {
    ...mapState(["loginRegistDialogVisible", "getPageUrl", "isMobile"]),
  },
  mounted() {
    window.addEventListener("keydown", this.keyDown);
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(to, from) {
        // console.log(to.path);

        if (to.path === "/login" && to.query.style) {
          this.$store.commit("changeLoginDialogVisible", to.query.style);
        } else {
          // this.closeDialog();
        }
      },
    },
    dialogType() {
      this.formInline = {
        dialCode: 86,
        account: "",
        sendCode: "",
        password: "",
      };
    },
    loginRegistDialogVisible(val) {
      if (val) {
        this.dialogType = val;
        this.formInline = {
          dialCode: 86,
          account: "",
          sendCode: "",
          password: "",
        };
        if (this.dialogType !== 6 && this.dialogType !== 7) {
          this.showDialog = false;
          return true;
        }
      }
      this.countdownCount = 60;
      this.showDialog = val ? true : false;
      this.init();

      this.newQrCode = setInterval(() => {
        if (!!this.websocket) {
          this.closeWebSocket();
          clearInterval(this.newQrCode);
          this.newQrCode = null;
        }
        this.qrcodeImgUrl = null;
        this.init();
      }, 300000); // 5分钟后调用
      window.onbeforeunload = function () {
        if (!!this.websocket) {
          this.closeWebSocket();
        }
      };
      clearInterval(this.interval);
    },
  },

  destroyed() {
    this.dialogType = 7;
    this.tittle = "登录超真云";
    window.removeEventListener("keydown", this.keyDown, false);
    clearInterval(this.newQrCode);
    this.newQrCode = null;
  },
  beforeDestroy() {
    this.closeWebSocket();
  },
  methods: {
    hide() {},
    init() {
      const {
        from,
        data: { registerSource },
      } = getChannelInfo();
      const query = {
        type: "PC_WEB_LOGIN",
      };
      if (from) {
        query["registerSource"] = registerSource;
      }
      qrcodeUrl(query).then((res) => {
        if (res.code == 200) {
          this.qrcodeImgUrl = res.data.qrcodeUrl;
          this.createSocket(res.data.ticket);
        }
      });
    }, // 建立websocket连接
    createSocket(ticket) {
      let that = this;
      if ("WebSocket" in window) {
        this.websocket = new WebSocket(
          // 'ws://192.168.2.28:9003/editor_server/websocket/' + ticket
          // 'wss://api.czy3d.com/editor_server/websocket/' + ticket
          process.env["VUE_APP_WEBSOCKET"] + ticket
        ); // 后台给的地址
        console.log("数据连接", this.websocket); // 这里会传递一些数据，
        //连接成功建立的回调方法
        this.websocket.onopen = function () {
          //setMessageInnerHTML("WebSocket连接成功");
          console.log("WebSocket连接成功");
        };
        //接收到消息的回调方法
        this.websocket.onmessage = (event) => {
          console.log("接收到消息的回调方法", event);

          let data = JSON.parse(event.data);
          // 判断当前用户是否绑定账号
          if (data.content.hasBind) {
            // 获取用户信息
            loginOrBind({ ticket: ticket, openid: data.content.openid }).then(
              (res) => {
                if (res.code == 200) {
                  var userInfo = res.userInfo;
                  userInfo.token = res.data;
                  setUserInfo(userInfo);
                  localStorage.setItem("refreshToken", res.refreshToken);
                  localStorage.setItem("401", -1);
                  //判断是否是新注册用户
                  if (data.content.isNewRegisterUser) {
                    console.log("websocket 新注册用户");
                    this.$emit("successRegister");
                  }
                }

                that.$showMessage("s", "扫码成功，可创建应用");
                that.$store.commit("changeLoginDialogVisible", 5);
                this.$store.dispatch("GetNewOrder");
                if (that.$store.state.isNowWin == 1) {
                  appClickHandle(that.$store.state.getPageUrl, "_self");
                } else if (that.$store.state.isNowWin == -1) {
                  that.$store.commit("changeLoginDialogVisible", false);
                } else {
                  that.$router.push(
                    that.$store.state.getPageUrl
                      ? that.$store.state.getPageUrl.path
                      : this.isMobile
                      ? "/"
                      : "/createProject"
                  );
                }
              }
            );
          } else {
            that.dialogType = 1;
            that.sendCode();
            sessionStorage.setItem("ticket", ticket);
            sessionStorage.setItem("openid", data.content.openid);
          }

          console.log("接收到消息的回调方法", event);
        };
      }
    },
    // 关闭Socket连接
    closeWebSocket() {
      console.log("websocket关闭", this.websocket);
      //  this.websocket.close();
    },
    keyDown(e) {
      // 非弹框页面不响应
      if (!this.showDialog) return;
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.submit(); // 需要执行的方法方法
      }
    },

    //关闭弹框
    closeDialog() {
      this.showDialog = false;
      this.$store.commit("changeLoginDialogVisible", false);
    },
    //提交-----------------------------------------------
    submit() {
      if (this.dialogType == 6) {
        //账号密码登录
        this.accountLogin();
      } else if (this.dialogType == 7) {
        //手机验证码登录
        this.codeLogin();
      } else if (this.dialogType == 8) {
        // 找回密码
        this.resetPassword();
      }
    },
    // 找回密码
    resetPassword() {
      resetPsw({
        phoneOrEmail: this.formInline.account,
        psw: this.formInline.pwd,
        code: this.formInline.password,
      }).then((res) => {
        this.countdownCount = 60;
        this.$showMessage("s", "重置密码成功");
        this.dialogType = 7;
      });
    },
    //账号密码登录
    accountLogin() {
      loginRegister(this.formInline.account, this.formInline.password).then(
        (res) => {
          if (res.code == 200) {
            var userInfo = res.userInfo;
            userInfo.token = res.data;
            setUserInfo(userInfo);
            localStorage.setItem("refreshToken", res.refreshToken);
            localStorage.setItem("401", -1);
            this.$showMessage("s", "登录成功，可创建应用");
            let c = this.$store.state.isNowWin;
            if (this.$store.state.isNowWin == 1) {
              appClickHandle(this.$store.state.getPageUrl);
            } else if (this.$store.state.isNowWin == -1) {
              this.$store.commit("changeLoginDialogVisible", false);
            } else {
              this.$router.push(
                this.$store.state.getPageUrl
                  ? this.$store.state.getPageUrl.path
                  : this.isMobile
                  ? "/"
                  : "/createProject"
              );
            }
            this.$store.dispatch("GetNewOrder");
            this.$store.commit("changeLoginDialogVisible", false);
            var width = document.documentElement.clientWidth;
            this.dialogType = 5;
          } else {
            this.$showMessage("e", res.message);
          }
        }
      );
    },
    //登录--手机验证码登录
    codeLogin() {
      let phone = this.formInline.account;
      if (phone.length < 11) {
        this.$showMessage("w", "请输入正确手机号码格式");
        return;
      }

      loginByPhoneCode(
        this.formInline.account,
        this.formInline.password,
        null
      ).then((res) => {
        if (res.code == 200) {
          this.countdownCount = 60;

          setTimeout(() => {
            var userInfo = res.userInfo;
            userInfo.token = res.data;
            setUserInfo(userInfo);
            localStorage.setItem("refreshToken", res.refreshToken);
            localStorage.setItem("401", -1);
            console.log(111111111111);

            if (res.isNewRegisterUser == true) {
              //通过该接口产生的新注册用户
              console.log("接口注册新用户");
              this.$emit("successRegister");
            }

            this.$showMessage("s", "登录成功，可创建应用");
            if (this.$store.state.isNowWin == 1) {
              appClickHandle(this.$store.state.getPageUrl);
            } else if (this.$store.state.isNowWin == -1) {
              this.$store.commit("changeLoginDialogVisible", 5);
            } else {
              this.$router.push(
                this.$store.state.getPageUrl
                  ? this.$store.state.getPageUrl.path
                  : this.isMobile
                  ? "/"
                  : "/createProject"
              );
            }
            this.$store.dispatch("GetNewOrder");
            this.$store.commit("changeLoginDialogVisible", 5);
          });
        } else {
          this.$showMessage("w", res.message);
        }
      });
    },
    //登录成功
    loginSuccess(val) {},
    //发送验证码
    sendCode: throttle(function (e) {
      var codeType = this.dialogType == 7 ? "AUTH_LOGIN" : "RESET_PASSWORD";
      if (this.countdownCount == 60) {
        let content = this.formInline.account; //手机号或邮箱内容
        if (content.length < 11) {
          this.$showMessage("w", "请输入正确手机号码格式");
          return;
        }
        //发送手机验证码
        sendPhoneCode(content, "", codeType).then((res) => {
          if (res.code == 200) {
            this.$showMessage("s", "验证码发送成功");
            this.countDown();
          } else {
            this.$showMessage("e", res.message);
          }
        });
      }
    }, 3000),

    countDown() {
      this.interval = setInterval(() => {
        if (this.countdownCount > 0) {
          this.countdownCount -= 1;
        } else {
          this.countdownCount = 60;
          clearInterval(this.interval);
          this.interval = null;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.input__code_icon {
  margin-top: 18px;
}

.reset-pwd {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  text-align: right;
  color: #fff;
  margin-top: -10px;
}

.top-sm {
  margin-top: 6px;
}

::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
}

/*解决浏览器输入框在记住密码后改变颜色问题*/
::v-deep input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #c2c2c2 inset;
  /* 自动填充文字颜色 */
  -webkit-text-fill-color: #555 !important;
  transition: background-color 5000s ease-in-out 0s;
}

.pc-wx {
  display: block;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.book {
  position: absolute;
  left: 20px;
  bottom: 14px;
  font-family: PingFang SC;
  font-size: 15px;
  font-weight: 500;
  text-align: left;

  color: #fff;

  > a {
    text-decoration: none;
    color: #fff;

    &:visited {
      color: #fff;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    left: 0;
    text-align: center;
    font-size: 12px;
  }
}

.login-btn {
  cursor: pointer;
  width: 310px;
  height: 48px;
  margin-top: 30px;
  border-radius: 10px;
  background-color: rgba(237, 72, 29, 1);
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 600;
  line-height: 68px;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.login-desc {
  width: 310px;
  text-align: center;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
  color: #fff;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

::v-deep .login-regis1 {
  width: 540px;
  min-height: 400px;
  margin-top: 50vh !important;
  transform: translateY(-50%);

  @media screen and (max-width: 768px) {
    width: 90vw;
  }

  .send-code-btn {
    position: absolute;
    width: 100px;
    right: 0px;
    color: #fff;
    cursor: pointer;
    text-align: right;
    padding-right: 10px;
  }

  .el-dialog__body {
    padding-top: 0;
  }

  .content1 {
    display: flex;
    margin-top: 10px;

    .left {
      width: 330px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .title {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 48px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }

    .tel {
      width: 334px;
      display: flex;

      .not-check {
        color: #c2c2c2;
      }

      > div {
        margin-right: 20px;
        cursor: pointer;
      }
    }

    .wx {
      width: 160px;
      height: 160px;
      border-radius: 10px;
      background-color: rgba(217, 217, 217, 1);
      transform: translate(0, 0);
    }
  }

  .phone {
    display: flex;

    .el-select {
      margin-right: 10px;
    }
  }

  .demo-form-inline {
    width: calc(100% - 20px);

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid #f57f22 !important;
  background-color: transparent;
}

::v-deep .el-checkbox__inner::after {
  top: 4px;
  left: 6px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f57f22;
  border-color: #f57f22;
}

.checkbox {
  z-index: 99;
}
</style>
