import { upload360WebConvert, uploadBaiDuWebConvert } from "@/api/tracking";

const storageChannelKey = "PromotionChannels";
const storageChannelExtraDataKey = "PromotionChannelsExtraData";

const defaultData = {};

// export const
let channelFrom = sessionStorage.getItem(storageChannelKey) || ""; // 360 bing baidu
let channelData = (() => {
  const data = sessionStorage.getItem(storageChannelExtraDataKey);
  try {
    return data ? JSON.parse(data) : defaultData;
  } catch (e) {
    return defaultData;
  }
})();

export function setChannelData(data) {
  channelData = data;
  sessionStorage.setItem(storageChannelExtraDataKey, JSON.stringify(data));
}

export function setChannelFrom(from, data) {
  channelFrom = from;
  sessionStorage.setItem(storageChannelKey, from);
  setChannelData(data);
}

export function resetChannel() {
  channelFrom = "";
  channelData = defaultData;
  sessionStorage.removeItem(storageChannelKey);
  sessionStorage.removeItem(storageChannelExtraDataKey);
}

export function getChannelInfo() {
  return {
    from: channelFrom,
    data: channelData || defaultData,
  };
}

/**
 * 页面注册事件上报函数
 *
 * @param type 上报类型，默认为 register submit
 */
export function pointRegister(type = "register") {
  const { from, data } = getChannelInfo();
  from && console.log("上报页面注册", from, data?.qhclickid);
  let event = "";

  switch (from) {
    case "bing":
      switch (type) {
        case "register":
          event = "signup";
          break;
        case "register-robolink":
          event = "signup_robolink";
          break;
        case "submit":
          event = "submit_lead_form";
          break;
        case "submit-robolink":
          event = "submit_lead_form_robolink";
          break;
        default:
      }
      window.uetq?.push("event", event, {});
      break;
    case "360":
      switch (type) {
        case "register":
          event = "REGISTERED";
          break;
        case "register-robolink":
          event = "REGISTERED";
          break;
        case "submit":
          event = "SUBMIT";
          break;
        case "submit-robolink":
          event = "SUBMIT";
          break;
        default:
      }
      if (data.qhclickid) {
        upload360WebConvert(data.qhclickid, event);
      }
      break;
    case "baidu":
      console.log("baidu register");
      console.log(data);
      let newType = "";
      switch (type) {
        case "register":
        case "register-robolink":
          newType = 25;
          break;
        case "submit":
        case "submit-robolink":
          newType = 3;
          break;
        default:
      }

      if (data.bd_vid && newType) {
        uploadBaiDuWebConvert(data.logidUrl, { newType });
      }
      break;
    default:
  }
}

// 页面进入的埋点 相当于pv
// 目前适用于活动页
// /marketing/240506
// robolink
export function pointPageLoad() {
  console.log("----pageLoad");
  const { from, data } = getChannelInfo();
  from && console.log("上报页面进入:", from, data);
  switch (from) {
    case "bing":
      window.uetq?.push("pageLoad");
      break;
    case "360":
      if (data.qhclickid) {
        upload360WebConvert(data.qhclickid, "COUSTOMIZE");
      }
      break;
    default:
  }
}
