var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-navs"},[_c('div',{staticClass:"nav_list",on:{"mouseenter":function($event){_vm.isShowFrame = true},"mouseleave":function($event){_vm.isShowFrame = false}}},_vm._l((_vm.navs),function(item,index){return _c('span',{key:index},[_c('div',{staticClass:"nav_title",class:_vm.navIndex == item.name ? 'check-title' : '',attrs:{"slot":"reference"},on:{"click":function($event){return _vm.navEvent(item)},"mouseenter":function($event){_vm.isNavIndex = index}},slot:"reference"},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowFrame),expression:"isShowFrame"}],staticClass:"nav_frame wow animate__fadeIn",attrs:{"data-wow-duration":".6s","data-wow-delay":"0s","data-wow-iteration":".6s"},on:{"mouseenter":function($event){_vm.isShowFrame = true},"mouseleave":function($event){_vm.isShowFrame = false}}},[_c('div',{staticClass:"nav_frame_main wow animate__fadeIn",attrs:{"data-wow-duration":"1s","data-wow-delay":"0s","data-wow-iteration":"1s"}},[_vm._l((_vm.navs[_vm.isNavIndex].sublevel),function(i,ind){return (_vm.isNavIndex == 1 || _vm.isNavIndex == 2)?_c('div',{staticClass:"nav_frame_main_item",style:({
          paddingBottom: '40px',
        })},[_c('div',{staticClass:"nav_frame_main_item_title"},[_vm._v(" "+_vm._s(i.name1)+" ")]),_vm._l((i.sub),function(its,index){return _c('div',{key:its.path,staticClass:"nav_frame_main_item_box",on:{"click":function($event){return _vm.getUseApp(its)}}},[_vm._v(" "+_vm._s(its.name)+" ")])})],2):_vm._e()}),_vm._l((_vm.navs),function(item,index){return (_vm.isNavIndex == index)?_c('div',{key:index,staticClass:"nav_frame_main_item",style:({
          marginLeft:
            index + 1 == _vm.navs.length
              ? _vm.isNavIndex * (74.5 / _vm.navs.length) + '%'
              : _vm.isNavIndex * (72.5 / _vm.navs.length) + '%',
          paddingBottom: item.sublevel.length > 0 ? '40px' : '0px',
        })},[(item.sublevel && item.sublevel.length > 0)?_vm._l((item.sublevel),function(i,j){return _c('div',{key:j,staticClass:"nav_frame_main_item_box",on:{"click":function($event){return _vm.navEvent(i, item.name)}}},[_vm._v(" "+_vm._s(i.name)+" ")])}):_vm._e()],2):_vm._e()})],2)]),_c('Namespacedialog',{attrs:{"showDialog":_vm.showDialog,"spaceId":_vm.spaceId},on:{"close":function($event){_vm.showDialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }