/**
 * 埋点统计相关*/
import request from "@/static/js/request";

export function upload360WebConvert(qhclickid, event) {
  return request({
    url: "/dianqing360/uploadWebConvert",
    method: "post",
    data: {
      qhclickid,
      event,
    },
  });
}

export function uploadBaiDuWebConvert(logidUrl, event) {
  return request({
    url: "/baidubd/uploadWebConvert",
    method: "post",
    data: {
      logidUrl,
      ...event,
    },
  });
}
